/* eslint-disable */
import { Travel, Result, Generics, RecentSearches } from '@/core/types';

export type State = {
  customer: Travel;
  result: Result;
  travellers_info: Array<Generics>;
  loading: boolean;
  recent_searches:Array<RecentSearches>;
  existingLeads: Array<any>
  selectedLeads: Array<number>
  exist_click: boolean
}

export const state: State = {
  customer: {
    id: null,
    lang: window.localStorage.getItem('language'),
    name: null,
    is_self: false,
    is_family: false,
    inbound_outbound:true,
    email: null,
    phone_number: null,
    phone_country_code: "+961",
    complete_phone_number: null,
    agent_id: window.localStorage.getItem('agent_id') ?? null,
    destination_id: [],
    no_of_traveller: 1,
    source_id: null,
    trip_type: null,
    destination_country:null,
    product_type: 1,
    start_date: null,
    end_date: null,
    no_of_days: 0,
    is_travel: 1,
    source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    dob: null,
    invoice: null,
    deleted_members:[],
    travellers_info: [],
    session_id : null
  } as Travel,
  
  existingLeads: [] as Array<any>,
  selectedLeads: [] as Array<number>,
  result: {} as Result,
  recent_searches: [] as Array<RecentSearches>,
  travellers_info: [] as Array<Generics>,
  loading: false,
  exist_click: false
};

