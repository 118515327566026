
import { computed, defineComponent, onMounted, ref, inject  } from "vue";
import { store } from "@/store";
const language = window.localStorage.getItem('language');

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Nationality",
    },
    formProp: {
      type: String,
      required: true,
      default: "nationality",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: language == 'ar' ? 'تحديد' : 'Select',
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {

    const countries = computed(() => {
      let allCountries = store.state.countries.countries;
      let obj = store.state.review.review_details.destination_id;
      if(store.state.review.review_details.source_id != uaeId.value) {
        return allCountries.filter((e)=> (e.id !== uaeId.value) && !obj.includes(e.id) )
      } else {
        return allCountries.filter(e => !obj.includes(e.id));
      }
    });
    
    const uaeId = computed(() => {
      return store.state.countries.uae_id;
    });

    onMounted(async () => {
      if(isMobile) {
          const nationality_teleport = document.getElementsByClassName('nationality_teleport')[0]
          nationality_ids.value = nationality_teleport.id

      }
    });


    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const nationality = ref()
    const isMobile = inject('isMobile')
    const nationality_ids = ref('')
    const nationality_search = ref('')


    const filtered_nationality = computed(() => {
      if(countries.value) {
        return countries.value.filter(obj => 
          obj.label.toString().toLowerCase().includes(nationality_search.value.toLowerCase())
        );
      }
      return []
    })

    const focus = () => {
      setTimeout(() => {
        nationality.value.focus()
      }, 500);
    }
    const colSize = (_.formProp == 'nationality_id') ? 'col-lg-12' : 'col-lg-12';
    return {
      nationality,
      colSize,
      focus,
      updateValue,
      countries,
      nationality_ids,
      nationality_search,
      isMobile,
      filtered_nationality
    };
  },
});
