<template>
  <div
    class="compare-list position-fixed w-100"
    :style="isOpen ? 'display: block' : 'display: none'"
  >
    <div class="container">
      <div class="compare-list-form">
        <form>
          <div class="row gx-5">
            <div
              class="col-md-3"
              v-for="(data, index) in compareList"
              :key="index"
            >
              <div class="compare-item position-relative">
                <div class="compare-item-block">
                  <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5 col-sm-5">
                      <div class="compare-item__logo">
                        <img
                          :src="`${domain}${url}/img/${data.provider.image}`"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7">
                      <div class="compare-item__name">
                        <p>
                          {{ data.wizard_plan_name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3" v-if="compareList.length == 2">
              <div class="compare-item position-relative add-to-compare-button">
                {{ $t("add_compare") }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="compare-button">
                <button
                  class="btn btn-compare-offers"
                  :disabled="isDisable"
                  @click.prevent="compareLists"
                >
                  {{ $t("compare_offer") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },

  setup() {
    const store = useStore();
    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);

    const compareList = computed(() => {
      return store.state.quotes.compare_list;
    });

    const removeCompare = (i) => {
      compareList.value.splice(i, 1);
    };

    const isDisable = ref(false);

    // handle click event
    const compareLists = async () => {
      await window.localStorage.setItem(
        "compare_data",
        JSON.stringify(compareList.value)
      );
      const appendPath = router.currentRoute.value.query ?? '';
      const routeName = 'CompareOffer'
      const route = router.resolve({ name: routeName, query: appendPath });
      window.location.href = route.href;
      
    };

    return {
      captcha,
      isDisable,
      compareList,
      domain,
      url,
      removeCompare,
      compareLists,
    };
  },
});
</script>
