import { App } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

/**
 * @description skeleton
 */
import HeaderView from '@/components/skeleton/HeaderView.vue';
import HeaderMobile from '@/components/skeleton/HeaderMobile.vue';
import FooterView from '@/components/skeleton/FooterView.vue';
import headerDef from '@/components/skeleton/headers/DefNav.vue';
import headerNoCompareQuotes from '@/components/skeleton/headers/NoCompareQuotes.vue';
import headerDeclarationThankyou from '@/components/skeleton/headers/DeclarationThankyou.vue';

/**
 * @description reusables
 */
import ResTitle from '@/components/reusables/Title.vue';
import inputText from '@/components/reusables/InputText.vue';
import inputCheckBox from '@/components/reusables/InputCheckBox.vue';
import inputInboundOutbound from '@/components/reusables/InputInboundOutbound.vue';
import selectDate from '@/components/reusables/SelectDate.vue';
import selectDateV2 from '@/components/reusables/SelectDateV2.vue';
import selectNationality from '@/components/reusables/SelectNationality.vue';
import selectInsuredType from '@/components/reusables/SelectInsuredType.vue';
import selectGender from '@/components/reusables/SelectGender.vue';
import selectRelationship from '@/components/reusables/SelectRelationships.vue';
import MembersForm from '@/components/reusables/Members.vue';
import SelectMaritalStatus from '@/components/reusables/SelectMaritalStatus.vue';
import buttonProceed from '@/components/reusables/ButtonProceed.vue';
import buttonBack from '@/components/reusables/ButtonBack.vue';
import existingLeads from '@/components/reusables/ExistingLeads.vue';
import modalError from '@/components/reusables/ModalError.vue';
import PageLoader from '@/components/reusables/PageLoader.vue';
import enInputCheckBox from '@/components/reusables/enInputCheckBox.vue';
import elDropdownRelationships from '@/components/reusables/ElDropdownRelationships.vue';

/**
 * @description views
 */
 import PersonalDetails from '@/components/forms/PersonalDetails.vue';
 import QuoteDetails from '@/components/forms/QuoteDetails.vue';
 import ReviewDetails from '@/components/forms/ReviewDetails.vue';
 import CompareDetails from '@/components/forms/CompareDetails.vue';
 
 import NoQuotesFound from '@/components/forms/NoQuotesFound.vue';

 /**
  * @description Myself, Domestic Workers, Family, Employee and Parents fields
  */
 import travellerInfo from '@/components/forms/Fields/TravellerForm.vue';
 import reviewMember from '@/components/forms/Fields/reviewMember.vue';

 import CompareList from '@/views/CompareList.vue';

export function initComponent(app: App<Element>) {

    const data = [
        { key: 'h-header', component: HeaderView },
        { key: 'h-footer', component: FooterView },
        { key: 'h-header-mobile', component: HeaderMobile },
        { key: 'h-header-default', component: headerDef },
        { key: 'h-header-no-compare-quotes', component: headerNoCompareQuotes },
        { key: 'h-header-no-compare-quotes-thank-you', component: headerDeclarationThankyou },
        { key: 'h-title', component: ResTitle },
        { key: 'h-input', component: inputText },
        { key: 'h-checkbox', component: inputCheckBox },
        { key: 'h-inbound-outbound', component: inputInboundOutbound },
        { key: 'h-date', component: selectDate },
        { key: 'h-date-v2', component: selectDateV2 },
        { key: 'h-nationality', component: selectNationality },
        { key: 'h-insured-type', component: selectInsuredType },
        { key: 'h-gender', component: selectGender },
        { key: 'h-relationship', component: selectRelationship },
        { key: 'h-proceed', component: buttonProceed },
        { key: 'h-back', component: buttonBack },
        { key: 'h-member-form', component: MembersForm },
        { key: 'h-personal-details', component: PersonalDetails },
        { key: 'h-type-traveller', component: travellerInfo },
        { key: 'h-review-member-form', component: reviewMember },
        { key: 'h-review-details', component: ReviewDetails },
        { key: 'h-quote-details', component: QuoteDetails },
        { key: 'h-modal-error', component: modalError },
        { key: 'h-page-loader', component: PageLoader },
        
        { key: 'h-existing-leads', component: existingLeads },
        { key: 'h-no-quotes', component: NoQuotesFound },
        { key: 'h-compare-list', component: CompareList},
        { key: 'h-compare-details', component: CompareDetails },
        { key: 'h-marital-status', component: SelectMaritalStatus },
        { key: 'el-input-check-box', component: enInputCheckBox },
        { key: 'el-relationships', component: elDropdownRelationships },

    ];

    data.forEach(element => {
        app.component(element.key, element.component);
    });

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}
