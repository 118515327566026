import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "compare-plans-table_block_item_row_item" }
const _hoisted_2 = { class: "compare-company text-center" }
const _hoisted_3 = { class: "compare-company_logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "compare-company_name" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "compare-company_price" }
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "proceed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: `${_ctx.domain}${_ctx.url}/img/${_ctx.quote.provider.image}`,
          class: "img-fluid"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.quote.wizard_plan_name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.quote.product_type_name), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h6", _hoisted_8, _toDisplayString(_ctx.currency.format(_ctx.quote.premium_round)), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", {
          href: "#",
          class: "btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.BuyNow(_ctx.quote)), ["prevent"]))
        }, _toDisplayString(_ctx.$t('proceed')), 1)
      ])
    ])
  ]))
}