import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Travel } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Travel
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload: Travel) {    
    commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      payload.preferred_language = window.localStorage.getItem('language')?.toString();
      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data.data;
      personal_details.customer.inbound_outbound = (personal_details.customer.source_id === 99 || personal_details.customer.source_id === null) ? true : false
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);
      const mapCustomer = customer(personal_details.customer, payload);
      window.localStorage.setItem('uuid', personal_details.uuid);
      
      commit(PersonalMutationTypes.SET_CUSTOMER, mapCustomer);
      
      window.localStorage.setItem('map_id', personal_details.lead_map_id)
      window.localStorage.setItem('session_id', personal_details.session_id);
      window.localStorage.setItem('personal_details', JSON.stringify(personal_details));
      const agent = router.currentRoute.value.query ? router.currentRoute.value.query.agent_id : null;
      if(!agent) {
        window.localStorage.setItem('device_uuid', personal_details.device_uuid);
      } else {
        window.localStorage.removeItem('device_uuid');
      }
      const appendPath = router.currentRoute.value.query ?? {};
      appendPath.uuid = personal_details.uuid;
      appendPath.sid = personal_details.session_id;

      /**
       * Removing state new from the url
       * before it changed to the compare quotes page
       */
      if ('state' in appendPath) {
        delete appendPath.state;
      }

      const routeName = 'CompareQuotes'
      const route = router.resolve({ name: routeName, query: appendPath });
      
      window.location.href = route.href;
    }
    catch (err) {
      return err;
    }
  },

  async [PersonalActionTypes.GET_PERSONAL_DETAILS] ({ commit }, payload) {
    try {
      const result = await axios.post(Links.GET_PERSONAL_DETAILS, payload) 
      // return data.data.data.personal;
      const data = result.data.data.customer;      
      const { get_customer, lead_details, get_invoice }  = data
      const sessionId = window.localStorage.getItem('session_id');
      const lang = window.localStorage.getItem('lang');
      const agent = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";
      const travel = {
        id: data.id,
        lang: lang ?? 'en',
        name: get_customer ? get_customer.name : null,
        is_self: data.is_self === 1 ? true : false,
        is_family: data.is_family === 1 ? true : false,
        email: get_customer ? get_customer.email : null,
        phone_number: get_customer ? get_customer.phone_number : null,
        phone_country_code: get_customer ? get_customer.phone_country_code : null,
        complete_phone_number:  get_customer ? get_customer.complete_phone_number : null,
        inbound_outbound: data.source_id === 99 || data.source_id === null ? true : false,
        agent_id: get_customer ? get_customer.agent_id : (agent ? parseInt(agent) : null),
        destination_id: data.destination_id,
        no_of_traveller: (lead_details.length > 0) ? lead_details.length : 1,
        source_id: data.source_id,
        trip_type: data.trip_type,
        product_type: data.product_type,
        start_date: data.start_date,
        end_date: data.end_date,
        no_of_days: data.no_of_days,
        is_travel: 1,
        source: data.customer_source,
        utm_source: data.utm_source,
        utm_medium: data.utm_medium,
        utm_campaign: data.utm_campaign,
        utm_content: data.utm_content,
        invoice:get_invoice,
        deleted_members:[],
        travellers_info: lead_details && lead_details.length > 0 ? members(lead_details) : [],
        session_id:sessionId ?? null,
      }
      commit(PersonalMutationTypes.SET_CUSTOMER, travel)

      if(data && data.recent_searches) 
        commit(PersonalMutationTypes.SET_CUSTOMER_RECENT_LEADS, data.recent_searches)

      } catch (err) {
      return err
    }
  },

  async [PersonalActionTypes.SET_CUSTOMER_LEADS]({ commit }, payload) {
    const data = payload.customer;
    const { get_customer, lead_details, get_invoice }  = data
    const sessionId = window.localStorage.getItem('session_id');
    const lang = window.localStorage.getItem('lang');    
    const agent = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";         
    const travel = {
      id: data.id,
      lang: lang ?? 'en',
      name: get_customer ? get_customer.name : null,
      is_self: data.is_self === 1 ? true : false,
      is_family: data.is_family === 1 ? true : false,
      email: get_customer ? get_customer.email : null,
      phone_number: get_customer ? get_customer.phone_number : null,
      phone_country_code: get_customer ? get_customer.phone_country_code : null,
      complete_phone_number: null,
      destination_id: data.destination_id,
      inbound_outbound: data.source_id === 99 || data.source_id === null ? true : false,
      no_of_traveller: (lead_details.length > 0) ? lead_details.length : 1,
      source_id: data.source_id,
      trip_type: data.trip_type,
      product_type: data.product_type,
      start_date: data.start_date,
      end_date: data.end_date,
      no_of_days: data.no_of_days,
      is_travel: 1,
      source: data.customer_source,
      utm_source: data.utm_source,
      utm_medium: data.utm_medium,
      utm_campaign: data.utm_campaign,
      utm_content: data.utm_content,
      invoice:get_invoice,
      deleted_members:[],
      travellers_info: lead_details && lead_details.length > 0 ? members(lead_details) : [],
      session_id:sessionId ?? null,
    }
    commit(PersonalMutationTypes.SET_CUSTOMER, travel)
  },
  async [PersonalActionTypes.GET_LATEST_LEADS] ({commit}, payload) {
    try {
      const data = await axios.post(Links.GET_LATEST_LEADS_BY_DEVICE_ID, payload) 
      if(typeof data.data === 'object') {
        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_LEADS, data.data.data.leads)
        commit(PersonalMutationTypes.SET_CUSTOMER_RECENT_LEADS, data.data.data.recent)
        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, mapAllLeads(data.data.data.leads))
      } else {
        commit(PersonalMutationTypes.SET_CUSTOMER_EXISTING_SELECTED_LEADS, [])
        commit(PersonalMutationTypes.SET_INSURED_TYPE_DISABLED, false)
      }
    } catch (err) {
      return err
    }
  },
};


function customer(data, payload) {
  const { lead_details, get_customer, get_invoice }  = data
  const sessionId = window.localStorage.getItem('session_id');       
  const agent = router.currentRoute.value.query && router.currentRoute.value.query.agent_id ? router.currentRoute.value.query.agent_id.toString() : "";

  const travel = {
    id: data.id,
    lang: payload.lang,
    name: get_customer ? get_customer.name : data.name,
    is_self: data.is_self === 1 ? true : false,
    is_family: data.is_family === 1 ? true : false,
    email: get_customer ? get_customer.email : null,
    phone_number: get_customer ? get_customer.phone_number : null,
    phone_country_code: get_customer ? get_customer.phone_country_code : null,
    complete_phone_number: null,
    inbound_outbound: data.inbound_outbound,
    agent_id: get_customer ? get_customer.agent_id : (agent ? parseInt(agent) : null),
    no_of_traveller: (lead_details.length > 0 ) ? lead_details.length : 1,
    destination_id: data.destination_id,
    source_id: data.source_id,
    trip_type: data.trip_type,
    product_type: data.product_type,
    start_date: data.start_date,
    end_date: data.end_date,
    destination_country:data.destination_country,
    no_of_days: data.no_of_days,
    is_travel: 1,
    source: data.customer_source,
    utm_source: data.utm_source,
    utm_medium: data.utm_medium,
    utm_campaign: data.utm_campaign,
    utm_content: data.utm_content,
    invoice:get_invoice ? get_invoice : null,
    deleted_members:[],
    travellers_info: lead_details && lead_details.length > 0 ? members(lead_details) : [],
    session_id:sessionId ?? null,
  }
  return travel;
}

function members(leads) {
  return leads.map((x, index) => {
    let lead = x;
    if(x.lead_information != undefined) {
      lead = x.lead_information;
    }
    return {
      id: lead.id,
      bai_misc_lead_id: lead.bai_misc_lead_id,
      type: lead.type,
      first_name: lead.first_name,
      middle_name: lead?.middle_name,
      last_name: lead?.last_name,
      dob: lead.dob,
      passport: lead.passport,
      gender_id: lead.gender_id,
      marital_status_id: lead.marital_status_id,
      nationality_id: lead.nationality_id,
      country_id: lead.country_id,
      relationship_type_id: lead.relationship_type_id == null && leads.length === 0 ? 5 : lead.relationship_type_id ,
      is_editable: leads.length === (index + 1) ? true : false
    }
  })
}
function mapAllLeads(data: any) {
  // const value = data.map(x => x.insured_type_id)
  return [...new Set(data)]
}

