
import { defineComponent, ref } from "vue";
import { store } from "@/store";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Relationship",
    },
    formProp: {
      type: String,
      required: true,
      default: "relationship",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdown: {
      type: Array,
      required: false,
      default: null,
    },
  },

  setup(_, context) {

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const relationship = ref()
    const focus = () => {
      setTimeout(() => {
        relationship.value.focus()
      }, 500);
    }

    const colSize = (_.formProp == 'relationship') ? 'col-lg-12' : 'col-lg-12';

    return {
      relationship,
      colSize,
      focus,
      updateValue,
    };
  },
});
